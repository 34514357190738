import axios from "axios";
import { validateResponse } from "./error-handler";
import { getCredentialsIfValid, getAuthToken } from "./auth/auth";

import { setLoginExpired } from "./../store/actions/login-actions";

import { store } from "./../index";

const appCredentials = {
  AppName: process.env.REACT_APP_APPNAME,
  AppKey: process.env.REACT_APP_APPKEY
};

const claimDocumentsfield = {
  IncludeClaimsDocuments: true
}

const getHeaders = (offset, limit) => {
  if(limit)
    Headers.Header.Limit = limit;
  if(offset>=0)
    Headers.Header.Offset = offset;
  return Headers;
}

let Headers = {
  Header: {
    Limit: 20,
    Offset: 0,
    Total: 0
  }
  
}

// Axios instance for accessing unauthenticated resources.
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  timeout: process.env.REACT_APP_AXIOS_NETWORK_TIMEOUT,
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

// Axios instance for accessing new APIs with custom headers.
const axiosInstanceWithAuth = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  timeout: process.env.REACT_APP_AXIOS_NETWORK_TIMEOUT,
  headers: {
    "X-Client": process.env.REACT_APP_API_X_CLIENT_HEADER,
    "X-Client-AuthToken": process.env.REACT_APP_API_X_CLIENT_AUTH_TOKEN,
    "Content-Type": "application/json"
  }
});

// appendUserCredentials(config, isNewAPI) - send flag as true for "isNewAPI" parameter - to add "email" field to request body.
axiosInstanceWithAuth.interceptors.request.use(
  function(config) {
    // Except for Authentication (Token) API append the token if locally available.
    if (!config.url.endsWith("oauth/token")) {
      appendAppCredentials(config);
      const isCredentialsAvailable = appendUserCredentials(config, true);
      if (isCredentialsAvailable) {
        return config;
      } else {
        store.dispatch(setLoginExpired(true));
      }
    } else {
      return config;
    }
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response Interceptor for New API's
axiosInstanceWithAuth.interceptors.response.use(
  function(response) {
    // Do something with response data

    return response;
  },
  function (error) {
      
    // Do something with response error
    if (error.response.status === 401 && !error.config.url.endsWith("oauth/token") && !error.config.url.endsWith("Accounts/AuthTest")) {
      //place your reentry code
      store.dispatch(setLoginExpired(true));
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  function(config) {
    appendAppCredentials(config);
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function(response) {
    // Do something with response data
    return validateResponse(response);
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// Axios instance for accessing authenticated resources.

const axiosInstanceNoAuth = axios.create({
    baseURL: process.env.REACT_APP_APIURL,
    timeout: process.env.REACT_APP_AXIOS_NETWORK_TIMEOUT,
    headers: { "Content-Type": "application/json", Accept: "application/json" }
});

const axiosInstanceOldAPI = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
  timeout: process.env.REACT_APP_AXIOS_NETWORK_TIMEOUT,
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

// Adding an interceptor to hook credentials in request if needed.
axiosInstanceOldAPI.interceptors.request.use(
  function(config) {
    appendAppCredentials(config);
    if(config.url.endsWith("Claims/GetClaimsByAccount")){
      appendIncludeClaimDocumentsKey(config);
      appendPageHeader(config);
    }
    const isCredentialsAvailable = appendUserCredentials(config);
    if (isCredentialsAvailable) {
      return config;
    } else {
      store.dispatch(setLoginExpired(true));
    }
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstanceOldAPI.interceptors.response.use(
  function(response) {
    // Do something with response data
    return validateResponse(response);
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// Configure Mocking.
// TODO: Handle Mocking stuff so that mocking is enabled only for

// var MockAdapter = require("axios-mock-adapter");

// This sets the mock adapter on the default instance
// var mock = new MockAdapter(axiosInstanceOldAPI);

// enableClinicsMock(mock);

// Helper Methods.

let appendAppCredentials = config => {
  if (config.data instanceof FormData) {
    config.data.append("AppName", appCredentials.AppName);
    config.data.append("AppKey", appCredentials.AppKey);
  } else {
    config.data = { ...appCredentials, ...config.data };
  }
};

let appendIncludeClaimDocumentsKey = config => {
  if (config.data instanceof FormData) {
    config.data.append("IncludeClaimsDocuments", claimDocumentsfield.IncludeClaimsDocuments);
  } else {
    config.data = { ...claimDocumentsfield, ...config.data };
  }
}

export let updatePageHeader = (offset) => {
  getHeaders(offset);
}

let appendPageHeader = config => {
  if (config.data instanceof FormData) {
    // config.data.append("Header", Headers);
    config.data.append("Header", getHeaders());
  } else {
    config.data = { ...getHeaders(), ...config.data };
  }
}

let appendUserCredentials = (config, isNewAPI = false) => {
  let credentials = getCredentialsIfValid();

  // This is because, Some API takes EmailAddress as key, some takes Email as key.
  let userNameKey = "EmailAddress";
  if (config.url.includes("Upload/Send")) {
    userNameKey = "Email";
  }

  if (credentials) {
    //new API requires email field in request body
    if (isNewAPI) {
      let authToken = getAuthToken();
      let authHeader = "Bearer " + authToken;
      config.headers = {...config.headers, Authorization: authHeader};

      config.data = { email: credentials.username, ...config.data };
      return true;
    }

    // If username is sent in the request, take the username and password. If not use the one from cache.
    // So at worst case if the during Login (MyAccounts) is called with new credentials
    //   and there is some old credentail cache is available it will take the credentials sent in request.
    if (config.data instanceof FormData) {
      config.data.append(userNameKey, credentials.username);
      config.data.append("Password", credentials.password);
    } else {
      let creds = {
        Password: credentials.password
      };
      creds[userNameKey] = credentials.username;
      config.data = { ...creds, ...config.data };
    }
    return true;
  }
  return false;
};

export { axiosInstance, axiosInstanceOldAPI, axiosInstanceWithAuth, axiosInstanceNoAuth };
