import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./MobileAppReviewRates.component.css";

import PetPlanCollapse from "./PetPlanCollapse.component";
import { Form } from "informed";
import SegmentButton from "../../common/form-fields/segment-button/SegmentButton";
import { getProtectedSinceDate } from "../../common/util/util";

class MobileAppReviewRates extends Component {

    constructor(props) {
        super(props);
        let showCoverageOptions = false;
        //if there is only one policy, we will not show coverage options UI
        if (props.policyDetails.policies.length > 1) {
            //we will show most popular plan if selected plan is default plan else we will show coverage options
            if (props.selectedPlan && !props.selectedPlan.default_plan) {
                showCoverageOptions = true;
            }
            //if none of the policies is default plan, only coverage options should be shown
            else if (!(this.props.policyDetails.policies.find(policy => policy.default_plan))) {
                showCoverageOptions = true;
            }
        }
        this.state = {
            showCoverageOption: showCoverageOptions,
            selectedPlan: props.selectedPlan ? props.selectedPlan : this.getDefaultPlan()
        }
        this.totalMonthlyRate_existingPets = 0;
        this.props.existingPets.map(pet => (this.totalMonthlyRate_existingPets += Number(pet.MonthlyPremium.split("$")[1])));

    }

    componentDidMount() {
        this.props.setNewRoute("/MobileAppAddPet/Step2");

        //For google analytics
        window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.pathname + '/Step2'
        });
    }

    enableCoverageOption = () => {
        this.setState({
            showCoverageOption: true
        })
    }

    getDefaultPlan = () => {
        let plan = this.props.policyDetails.policies.find(policy => policy.default_plan);
        if (!plan && this.props.policyDetails.policies.length > 0) {
            plan = this.props.policyDetails.policies[0];
        }
        return plan;
    }

    reimbursementOptionsControl = (selectedPlan) => {
        let reimbursementOptions = [];
        const defaultValue = selectedPlan.reimbursement;
        let default_plan = this.getDefaultPlan();

        this.props.policyDetails.policies.map(policy => {
            if (!reimbursementOptions.find(ele => ele.value === policy.reimbursement)) {
                let obj = { label: policy.reimbursement * 100 + "%", value: policy.reimbursement }
                if (default_plan.reimbursement === policy.reimbursement && default_plan.default_plan) {
                    obj.info = 'Most Popular';
                }
                reimbursementOptions.push(obj);
            }
            return reimbursementOptions;
        });
        reimbursementOptions.sort((op1, op2) => {
            if (op1.value > op2.value) return -1;
            return 1;
        })

        return (
            <div className="text-left">
                <p className="mb-1">Select Reimbursement Option:</p>
                <SegmentButton className="mb-1" id="reimbursement" initialValue={defaultValue} value={default_plan.reimbursement} field="reimbursement" data={reimbursementOptions}></SegmentButton>
            </div>
        )
    };

    deductibleOptionsControl = (selectedPlan) => {
        let deductibleOptions = [];
        const defaultValue = selectedPlan.deductible;
        const default_plan = this.getDefaultPlan();
        this.props.policyDetails.policies.map(policy => {
            if (!deductibleOptions.find(ele => ele.value === policy.deductible)) {
                let obj = { label: "$" + policy.deductible, value: policy.deductible }
                if (default_plan.deductible === policy.deductible && default_plan.default_plan) {
                    obj.info = 'Most Popular';
                }
                deductibleOptions.push(obj);
            }
            return deductibleOptions;
        });
        deductibleOptions.sort((op1, op2) => {
            if (op1.value > op2.value) return 1;
            return -1;
        })

        return (
            <div className="text-left">
                <p className="mb-1">Select Deductible Option:</p>
                <SegmentButton className="mb-1" id="deductible" initialValue={defaultValue} value={default_plan.deductible} field="deductible" data={deductibleOptions}></SegmentButton>
            </div>
        )
    };

    getCoverageOption = (selectedPlan) => {
        const { annual_limit, monthly_premium } = selectedPlan;
        const { petAge } = this.props;
        return (
            <div className="row mx-0">
                <div className="col-md-3 p-0 d-none d-md-flex align-items-center mr-4">
                    {petAge < 8 && <p className="text-center py-4 pr-2 text-primary arrow_box">Available coverage options based on your pet's details</p>}
                </div>
                <div className="col p-0">
                    {this.reimbursementOptionsControl(selectedPlan)}
                    {this.deductibleOptionsControl(selectedPlan)}
                    <p className="text-left mb-1">Maximum Payouts: <span className="font-weight-bold"> {annual_limit === "99999" ? "Unlimited" : annual_limit} </span></p>
                    <div className="d-md-none rate-text text-center">${Number(monthly_premium).toFixed(2)}<small>/month</small></div>
                </div>
            </div>)
    }

    getMostPopularPlan = (selectedPlan) => {
        const { reimbursement, deductible, annual_limit, monthly_premium } = selectedPlan;
        const { policyDetails } = this.props;
        return (
            <div className="text-center text-md-left">
                <h4 className="">{(policyDetails.policies.length === 1) ? "Available coverage options based on your pet's details" : "Most Popular Plan"}</h4>
                <p className="mb-0">Reimbursement: <span className="font-weight-bold"> {reimbursement * 100}% </span></p>
                <p className="mb-0">Annual Deductible: <span className="font-weight-bold"> ${deductible} </span></p>
                <p className="mb-1 mb-md-4">Maximum Payouts: <span className="font-weight-bold"> {annual_limit === "99999" ? "Unlimited" : annual_limit} </span></p>
                {(policyDetails.policies.length > 1) &&
                    <span className="font-weight-bold customize-rate-link" onClick={this.enableCoverageOption}>Customize Rate</span>
                }
                <div className="d-md-none mt-3 rate-text"> ${Number(monthly_premium).toFixed(2)}<small>/month</small></div>
            </div>
        )
    }

    petPlanList = () => (
        this.props.existingPets.map((pet) => {
            return (
                <PetPlanCollapse key={pet.PetID} plan={pet} />
            );
        })
    );

    planChangeHandler = (formState) => {
        const { reimbursement, deductible } = formState.values;
        const selectedPlan = this.props.policyDetails.policies.find(plan => (plan.reimbursement === reimbursement && plan.deductible === deductible));
        selectedPlan && this.setState({ selectedPlan });
    }

    nextStep = () => {
        this.props.setSelectedPlan(this.state.selectedPlan, (this.totalMonthlyRate_existingPets + this.state.selectedPlan.monthly_total_payment).toFixed(2));
        this.props.stepper.nextStep();
    }

    stateVerification = (state) => {
        if (state === "NH" || state === "MD")
            return true;
        return false;
    }

    goToPreviousStep = () => {
        this.props.setSelectedPlan(null, 0);
        this.props.stepper.previousStep();
    }

    render() {
        const { selectedPlan } = this.state;
        const breed = this.props.breedsData.find(breed => breed.id === Number(this.props.petDetails.breed));
        const billingState = this.props.physicalAddress.State;

        return (
            <Form
                onChange={(formState) => this.planChangeHandler(formState)}
                onSubmit={this.nextStep}
            >
                <div className="container review-rates">
                    <h1 className="text-primary mb-3 mb-lg-0 text-truncate">2. Review Rates for  <span style={{ textTransform: "capitalize" }} >{this.props.petDetails.petName}</span></h1>
                    <div className="row">
                        <div className="col-lg-8 d-flex flex-column flex-fill">
                            <div className="mb-2">
                                <h2 className="d-md-inline mr-1 font-weight-bold">
                                    {
                                        this.props.petDetails.petType === "dog" ?
                                            <img className="d-none mr-2 d-md-inline pet-icon" alt="dog" src="../../assets/images/add-dog.svg"></img> :
                                            <img className="d-none mr-2 d-md-inline pet-icon" alt="cat" src="../../assets/images/add-cat.svg"></img>
                                    }
                                    <span className="text-dark truncate d-none d-md-inline-block" style={{ textTransform: "capitalize", verticalAlign: "middle" }} >{this.props.petDetails.petName}</span>
                                </h2>
                                <div className="d-flex d-md-none justify-content-between">
                                    <h2 className="truncate  text-medium" style={{ textTransform: "capitalize", verticalAlign: "middle" }} >{this.props.petDetails.petName}</h2>
                                    <span className="link-text" onClick={() => this.goToPreviousStep()} ><img className="pencil-edit-custom" alt="edit" src="/assets/images/pencil-edit.svg"></img></span>
                                </div>
                                <span className="d-block d-md-inline ml-1">{this.props.petAge === 0 ? this.props.petDetails.petType === "dog" ? "Puppy" : "Kitten" : "Age " + this.props.petAge}, {breed.name}</span>
                                <div className="link-text d-none d-md-inline-block ml-2" style={{ fontSize: "14px" }} onClick={() => this.goToPreviousStep()} >Edit Pet</div>
                            </div>

                            <div className="plan-selector-container d-flex">
                                <div className={`review-sub-content shaded ${this.state.showCoverageOption ? "col-md-8" : "col-md-6"}`}>
                                    {this.state.showCoverageOption ? this.getCoverageOption(selectedPlan) : this.getMostPopularPlan(selectedPlan)}
                                </div>
                                <div className="col d-none d-md-flex flex-column review-sub-content">
                                    <h4 style={{ textTransform: "capitalize" }} ><span className="truncate">{this.props.petDetails.petName}</span>'s Rate</h4>
                                    <h2 className="my-4 rate-text"> ${Number(selectedPlan.monthly_premium).toFixed(2)}<small>/month</small></h2>
                                </div>
                            </div>
                            {this.petPlanList()}

                        </div>
                        <div className="col-lg-4">
                            <div className="new-rate-container">
                                <div className="rate-container text-center">
                                    <h2 className="font-weight-bold d-none d-md-block">New Total Monthly Rate For Your Pets</h2>
                                    <h4 className="d-md-none"> Total Monthly Rate For Your Pets</h4>
                                    <div className="rate-text-large">{"$" + (this.totalMonthlyRate_existingPets + selectedPlan.monthly_total_payment).toFixed(2)}<small>/month</small></div>
                                    <div className="button-container mt-3 d-none d-md-flex flex-column">
                                        <button
                                            className="button-primary"
                                            type="button"
                                            onClick={this.nextStep}
                                        >Next
                                        </button>
                                        <Link to="/" >
                                            <button
                                                className="button-link"
                                                type="button"
                                            >Cancel
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="effective-details d-md-none">
                                    {billingState != "ME" && <p className="effective-date">Your coverage begins on {this.props.policyDetails.policy_effective_date} 12:01am plus applicable waiting periods.</p>}
                                    {billingState === "ME" && <p className="effective-date"><span className="truncate" style={{ textTransform: "capitalize" }} >{this.props.petDetails.petName}</span>'s policy effective date is {this.props.policyDetails.policy_effective_date}</p>}
                                    <ul>
                                        {((!this.stateVerification(billingState) && billingState != "ME") || (billingState === "ME" && !this.props.userAccountDetails.ModelLawInEffect)) && <li>A 15-day waiting period applies for accidents and illnesses.</li>}
                                        {((!this.stateVerification(billingState) && billingState != "ME") || (billingState === "ME" && !this.props.userAccountDetails.ModelLawInEffect)) && this.props.petAge < 6 && <li>A 12-month waiting period applies for illnesses related to hip dysplasia.</li>}
                                        {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && <li>There is a 15-day waiting period for illnesses, injuries, and orthopedic conditions (other than hip dysplasia) not resulting from an accident.</li>}
                                        {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && this.props.petAge < 6 && <li>There is a 30-day waiting period for hip dysplasia not resulting from an accident for pets younger than six years old.</li>}
                                        {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && <li>However, waiting periods will not apply if waived by us upon completion of a complete clinical examination.</li>}
                                    </ul>
                                </div>
                                <div className="effective-details d-none d-md-flex">
                                    <i className="fa fa-calendar calendar-icon"></i>
                                    <div className="">
                                        <p className="effective-date"><span className="truncate" style={{ textTransform: "capitalize" }} >{this.props.petDetails.petName}</span>'s policy effective date is {this.props.policyDetails.policy_effective_date}</p>
                                        <ul>
                                            {((!this.stateVerification(billingState) && billingState != "ME") || (billingState === "ME" && !this.props.userAccountDetails.ModelLawInEffect)) && <li>There is a 15-day waiting period for all accidents and illnesses. The waiting period starts on your policy effective date, {getProtectedSinceDate(this.props.policyDetails.policy_effective_date)}</li>}
                                            {((!this.stateVerification(billingState) && billingState != "ME") || (billingState === "ME" && !this.props.userAccountDetails.ModelLawInEffect)) && this.props.petAge < 6 && <li>There is a 12-month waiting period for hip dysplasia for pets younger than six years old.</li>}
                                            {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && <li>There is a 15-day waiting period for illnesses, injuries, and orthopedic conditions (other than hip dysplasia) not resulting from an accident.</li>}
                                            {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && this.props.petAge < 6 && <li>There is a 30-day waiting period for hip dysplasia not resulting from an accident for pets younger than six years old.</li>}
                                            {billingState === "ME" && this.props.userAccountDetails.ModelLawInEffect && <li>However, waiting periods will not apply if waived by us upon completion of a complete clinical examination.</li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none sticky-bottom">
                        <div className="col-md-8 p-0">
                            <div className="d-md-none text-center mb-1 text-uppercase"><span className="font-weight-bold">Step 2</span> of 3</div>
                            <button
                                className="button-primary-plusV2"
                                type="submit"
                            > 

                                <div class="">
                                     <div class="">
                                        Confirm Rate
                                        <img className="next-arrow-circle-right-custom" alt="next" src="/assets/images/next-arrow.svg"></img>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export default MobileAppReviewRates;