import * as types from "../../constants/action-types";

const initialState = {
  loggedIn: null,
  CCTLoggedIn: null,
  loginExpired: false,
  changePassword: false,
  returnURL: false
};

// Handles login related actions
export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_LOGIN_STATE:
      return { ...state, loggedIn: action.state };
    case types.SET_CCT_LOGIN_STATE:
      return { ...state, CCTLoggedIn: action.state };
    case types.SET_CHANGE_PASSWORD:
      return { ...state, changePassword: action.state };
    case types.SET_LOGIN_EXPIRED:
      return { ...state, loginExpired: action.state };
    case types.SET_RETURN_URL:
      return { ...state , returnURL: action.state };
    case types.LOGOUT_SUCCESS:
      localStorage.removeItem("auth-token");
      return { loggedIn: false, CCTLoggedIn: false };
    default:
      return state;
  }
}
