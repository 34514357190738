import { axiosInstanceOldAPI } from "./axios";

export const getVetClinics = () => {
  let url = "/VetClinics/GetVetClinicsByAccount";
  return axiosInstanceOldAPI
    .post(url, {})
    .then(response => {
      if (response.data) return response.data;
      else
        return Promise.reject({
          errorCode: null,
          errorDescription: null,
          errorMessage: null
        });
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
};
