import { axiosInstanceWithAuth } from "./axios";
import { axiosInstanceNoAuth } from "./axios";
import { axiosInstanceOldAPI } from "./axios";


export const getPolicyDocs = () => {
    let url = "/Documents/PolicyDocuments";
    return axiosInstanceWithAuth
      .post(url)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        let err = {
          errorCode: null,
          errorDescription: null,
          errorMessage: null
        };
        return Promise.reject(err);
      });
  };

  export const getContentDocument = (docId, type) => {
    
    let url = "Documents/ContentDocument";
    let axiosInstance = null;
    var jsonBody = null;
 
    axiosInstance = axiosInstanceWithAuth;
    
    if (type === "signature"){

      type += "_page";
      
    } 
    //docId = "001F000000jDiyL";
    jsonBody = {
        "document_id": docId,
        "document_type": type
    }

  

      return axiosInstance
      .post(url,jsonBody)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        let err = {
          errorCode: null,
          errorDescription: null,
          errorMessage: null
          };
         
        return Promise.reject(err); 
      }); 
  }


  export const getPolicyDoc = (docId, type) => {
    let url = "Documents/PolicyDocument";
    let axiosInstance = null;
    var jsonBody = null;

    if (type === "eob" || type === "arn") {
        axiosInstance = axiosInstanceNoAuth;
    } else {
        axiosInstance = axiosInstanceWithAuth;
    }
    
    if (type === "aws_s3") {
      jsonBody = {
        "resource_url": docId,
        "salesforce_type": type
      }
    } else {
      jsonBody = {
        "document_id": docId,
        "salesforce_type": type
      }
    }

    return axiosInstance
      .post(url,jsonBody)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        let err = {
          errorCode: null,
          errorDescription: null,
          errorMessage: null
          };
         
        return Promise.reject(err); 
      });
  }

export const markDocumentOpened = (docId) => {
  let url = "Documents/MarkDocumentOpened";
  let docIdPayload = {
    "Id" : docId
  }

  return axiosInstanceOldAPI
    .post(url, docIdPayload)
    .then(response => {
      return response;
    })
    .catch(function(err) {
      let error = {
        errorCode: -1000,
        errorDescription: "Unable to mark document read.",
        errorMessage: "Unable to mark document read. Try again later"
      };
      return Promise.reject(error);
    });

}