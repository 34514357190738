import 'babel-polyfill';
import 'nodelist-foreach-polyfill';
import objectFitImages from 'object-fit-images';
import 'classlist-polyfill';

import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import configureStore from './store';

import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "font-awesome/css/font-awesome.min.css";

import App from "./App";


// Initialize store
export const store = configureStore();

//objectFitImages();

objectFitImages(null, {watchMQ: true})

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
