export const CHECK_TOKEN_EXISTS = "CHECK_TOKEN_EXISTS";

//Login
export const SET_LOGIN_STATE = "SET_LOGIN_STATE";
export const SET_CCT_LOGIN_STATE = "SET_CCT_LOGIN_STATE";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";
export const SET_LOGIN_EXPIRED = "SET_LOGIN_EXPIRED";
export const SET_RETURN_URL = "SET_RETURN_URL";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const ACCOUNT_FETCH_REQUEST = "ACCOUNT_FETCH_REQUEST";
export const ACCOUNT_FETCH_SUCCESS = "ACCOUNT_FETCH_SUCCESS";
export const ACCOUNT_FETCH_ERROR = "ACCOUNT_FETCH_ERROR";
export const SET_ACCOUNT_DETAILS= "SET_ACCOUNT_DETAILS";
export const SET_RETRY_BILLING_STATUS = "SET_RETRY_BILLING_STATUS";
export const SET_USER_EDIT_STATE = "SET_USER_EDIT_STATE";

export const GET_TOP_ALERT_MESSAGE = "GET_TOP_ALERT_MESSAGE";
export const SET_TOP_ALERT_MESSAGE = "SET_TOP_ALERT_MESSAGE";
export const DISMISS_TOP_ALERT_NOTIFICATION = "DISMISS_TOP_ALERT_NOTIFICATION";

export const LOGOUT = "LOGOUT";

//Home
export const SCREEN_SIZE_UPDATE = "SCREEN_SIZE_UPDATE";
export const MESSAGE_TOGGLE = "MESSAGE_TOGGLE";

//Pets
export const PETS_FETCH_REQUEST = "PETS_FETCH_REQUEST";
export const PETS_FETCH_SUCCESS = "PETS_FETCH_SUCCESS";
export const PETS_FETCH_ERROR = "PETS_FETCH_ERROR";

export const SET_PETDETAILS_DATA_REFRESH = "SET_PETDETAILS_DATA_REFRESH";
export const SET_PETS_FETCHING_STATE = "SET_PETS_FETCHING_STATE";

export const PET_PHOTO_UPDATE_REQUEST = "PET_PHOTO_UPDATE_REQUEST";
export const PET_PHOTO_UPDATE_SUCCESS = "PET_PHOTO_UPDATE_SUCCESS";
export const PET_PHOTO_UPDATE_ERROR = "PET_PHOTO_UPDATE_ERROR";
export const PET_PHOTO_UPDATE_MODAL= "PET_PHOTO_UPDATE_MODAL";
export const PET_PHOTO_UPDATE_LOADER= "PET_PHOTO_UPDATE_LOADER";

export const GET_AVAILABLE_POLICY_OPTIONS = "GET_AVAILABLE_POLICY_OPTIONS";
export const GET_AVAILABLE_POLICY_OPTIONS_SUCCESS = "GET_AVAILABLE_POLICY_OPTIONS_SUCCESS";
export const GET_AVAILABLE_POLICY_OPTIONS_ERROR = "GET_AVAILABLE_POLICY_OPTIONS_ERROR";
export const GET_ANNIVERSARY_POLICY_OPTIONS = "GET_ANNIVERSARY_POLICY_OPTIONS";
export const GET_ANNIVERSARY_POLICY_OPTIONS_SUCCESS = "GET_ANNIVERSARY_POLICY_OPTIONS_SUCCESS";
export const GET_ANNIVERSARY_POLICY_OPTIONS_ERROR = "GET_ANNIVERSARY_POLICY_OPTIONS_ERROR";

//Claims
export const CLAIMS_FETCH_REQUEST = "CLAIMS_FETCH_REQUEST";
export const CLAIMS_FETCH_SUCCESS = "CLAIMS_FETCH_SUCCESS";
export const CLAIMS_FETCH_ERROR = "CLAIMS_FETCH_ERROR";
export const SET_CLAIMS_DATA_REFRESH = "SET_CLAIMS_DATA_REFRESH";
export const SET_CLAIMS_FETCHING_STATE = "SET_CLAIMS_FETCHING_STATE";

//USER-ACCOUNT
export const SET_ACCOUNT_DATA_REFRESH = "SET_ACCOUNT_DATA_REFRESH";
export const SET_USER_ACCOUNT_FETCHING_STATE = "SET_USER_ACCOUNT_FETCHING_STATE";

//Messages
export const MESSAGES_FETCH_REQUEST = "MESSAGES_FETCH_REQUEST";
export const MESSAGES_FETCH_SUCCESS = "MESSAGES_FETCH_SUCCESS";
export const MESSAGES_FETCH_ERROR = "MESSAGES_FETCH_ERROR";
export const SET_MESSAGE_READ = "SET_MESSAGE_READ";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const MESSAGE_EXPAND = "MESSAGE_EXPAND";
export const SET_MESSAGES_DATA_REFRESH = "SET_MESSAGES_DATA_REFRESH";
export const MESSAGES_COUNT_FETCH_SUCCESS = "MESSAGES_COUNT_FETCH_SUCCESS";

//Documents
export const DOCUMENTS_FETCH_REQUEST = "DOCUMENTS_FETCH_REQUEST";
export const DOCUMENTS_FETCH_SUCCESS = "DOCUMENTS_FETCH_SUCCESS";
export const DOCUMENTS_FETCH_ERROR = "DOCUMENTS_FETCH_ERROR";
export const SET_DOCUMENT_OPENED = "SET_DOCUMENT_OPENED";
export const SET_DOCUMENTS_DATA_REFRESH = "SET_DOCUMENTS_DATA_REFRESH";

//Logging
export const LOG_EVENT = "LOG_EVENT";

//Alerts
export const ALERTS_FETCH_REQUEST = "ALERTS_FETCH_REQUEST";
export const ALERTS_FETCH_SUCCESS = "ALERTS_FETCH_SUCCESS";
export const ALERTS_FETCH_ERROR = "ALERTS_FETCH_ERROR";
export const SET_ALERTS_DATA_REFRESH = "SET_ALERTS_DATA_REFRESH";
export const SET_NEW_ROUTE = "SET_NEW_ROUTE";
export const SET_NOTIFICATIONS_DISMISSED = "SET_NOTIFICATIONS_DISMISSED";
export const CLIENT_ALERT_ADD = "CLIENT_ALERT_ADD";
export const CLIENT_ALERT_REMOVE = "CLIENT_ALERT_REMOVE";
export const SET_CURRENT_PAGE_ALERTS = "SET_CURRENT_PAGE_ALERTS";

// Chat
export const CHAT_ENABLED_REQUEST = "CHAT_ENABLED_REQUEST";