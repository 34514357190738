import AES from "crypto-js/aes";
import CryptoJSUtf8Enc from "crypto-js/enc-utf8";

export const clearSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("auth-token");
  localStorage.removeItem("expiresOn");
  localStorage.removeItem("storeLastUpdated");
}


export const getCredentialsIfValid = () => {
  let expiryTimeStamp = localStorage.getItem("expiresOn");
  let nowTimeStamp = new Date().getTime();
  if (expiryTimeStamp && nowTimeStamp <= expiryTimeStamp) {
    let token = localStorage.getItem("token");
    if (token) {
      let credentials = decryptToObject(token);
      return credentials;
    }
  }
  return null;
};


export const getAuthToken = () => {
  let token = localStorage.getItem("auth-token");
  if (token) {
    return token;
  }
  return null;
}

//TODO: Remove this once all API's are migrated to proper Authentication API.
export const updatePasswordToken = ({username, password}) => {
  let credentials = {
    username,
    password
  }
  let cipherText = encryptObject(credentials);
  localStorage.setItem("token", cipherText);
}


export const saveCredentials = ({username, password, rememberMe}, authResponse) => {
  let credentials = {
    username,
    password
  }
  let cipherText = encryptObject(credentials);
  localStorage.setItem("token", cipherText);
  localStorage.setItem("auth-token", authResponse.access_token);
  
  // Expiry TimeStamp Based on Auth API
  let expiryTimeStamp;
  // Better to use .issued timeStamp from API
  let currentTimeStamp = new Date().getTime();
  expiryTimeStamp = currentTimeStamp + authResponse.expires_in * 1000; // Converting seconds in API to milliseconds.

  localStorage.setItem("expiresOn", expiryTimeStamp);
};


const encryptObject = (data) => {
  let cipherText = AES.encrypt(JSON.stringify(data), 'h3417hyp4w5'); 
  return cipherText;
}

const decryptToObject = (cipherText) => {
  let bytes = AES.decrypt(cipherText.toString(), 'h3417hyp4w5');
  let decryptedData = JSON.parse(bytes.toString(CryptoJSUtf8Enc));
  return decryptedData;
}