import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { getClaimsByAccount } from "../../../../api/claims-api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getClaimsByAccount } from "../../../../store/actions/claims-actions";
import { AppLinks } from "../../../../constants/app-links";
import ClaimStatus from "../../../claims/components/ClaimStatus";
import Loader from "../../../common/loader/Loader";
import "./ViewClaimStatusInfo.css";

class ViewClaimStatusInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            claims: null,
            claimsLength: null
        }
    }

    componentDidMount() {
        this.props.actions.getClaimsByAccount();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.claimsDetails !== state.claims) {
            return {
                claims: props.claimsDetails.length > 3 ? props.claimsDetails.slice(0, 3) : props.claimsDetails,
                claimsLength: props.claimsDetails.length || 0
            };
        }
        return null;
    }


    render() {
        return (
            <div className="view-claims-info" >
                <h2 className="header mb-0" >View Claim Status</h2>
                <span className="subtitle" >Recent Claims</span>
                {this.state.claimsLength > 0 ?
                    (
                        <React.Fragment>
                            <div className="body" >
                                <div>
                                    <ClaimsTable claims={this.state.claims} />
                                </div>
                            </div>
                            {this.state.claimsLength > 4 &&
                                <div className="footer" >
                                    <Link to={AppLinks.claims.claimStatus} >See More Claims</Link>
                                </div>}
                        </React.Fragment>
                    ) :
                    (
                        <div className="no-claims d-flex justify-content-center align-items-center text-center" >
                            {   this.props.fetching ? <Loader position="absolute" /> :
                                this.props.error ? "Unable to fetch claims right now." : "There is no claim data to show you right now."
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}

const ClaimsTable = ({ claims }) => {
    return (
        <div className="claims-table" >
            <div className="claims-striped-table" >
                {claims.map((claim) => (
                    <Link key={claim.ClaimID} to={{ pathname: AppLinks.claims.claimStatus, search: "?id=" + claim.ClaimID }} className="stripes" >
                        <div className="claim-item" key={claim.ClaimID} id={"toggler-" + claim.ClaimID}>
                            <div className="d-flex" >
                                <div className="col-lg-4 text-center" > {claim.DateSubmitted} </div>
                                <div className="col-lg-4 text-truncate text-center" > {claim.PetName} </div>
                                <div className="col-lg-4 text-center" > <ClaimStatus status={claim.StatusDetail} id={"toggler-" + claim.ClaimID} /> </div>
                            </div>
                        </div>
                    </Link>
                ))
                }
            </div>
        </div>
    )
}

// Subscribe component to redux store and merge the state into
// component's props
const mapStateToProps = state => ({
    claimsDetails: state.claims.claimsDetails,
    fetching: state.claims.fetchingClaims,
    error: state.claims.error
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators({ getClaimsByAccount }, dispatch)
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewClaimStatusInfo);