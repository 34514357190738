import * as actionTypes from "../constants/action-types";
import { getClaimsByAccount } from "../api/claims-api";
import { call, put, select } from "redux-saga/effects";

const getClaimsDetailsFromState = state => {
  return {
    claimsDetails: state.claims.claimsDetails,
    needsRefresh: state.claims.needsRefresh
  };
};

export function* getClaimsByAccountWorker() {
  try {
    const claimsDetailsFromState = yield select(getClaimsDetailsFromState);
    if (
      claimsDetailsFromState.claimsDetails.length === 0 ||
      claimsDetailsFromState.needsRefresh
    ) {
      yield put({ type: actionTypes.SET_CLAIMS_FETCHING_STATE });
      let claimsDetails = yield call(getClaimsByAccount);
      if (!claimsDetails) {
        claimsDetails = [];
      }
      yield put({ type: actionTypes.CLAIMS_FETCH_SUCCESS, claimsDetails });
    }
  } catch (error) {
    yield put({ type: actionTypes.CLAIMS_FETCH_ERROR, error });
  }
}
