import * as types from "../../constants/action-types";

const initialState = {
  claimsDetails: [],
  error: null,
  needsRefresh: false,
  fetchingClaims: false
};

// Handles image related actions
export default function(state = initialState, action) {
  switch (action.type) {
    case types.CLAIMS_FETCH_REQUEST:
      return {
        ...state
      };
    case types.CLAIMS_FETCH_SUCCESS:
      return {
        ...state,
        claimsDetails: action.claimsDetails.data || [],
        pagination: action.claimsDetails.pagination,
        fetchingClaims: false,
        needsRefresh: false
      };
    case types.SET_CLAIMS_FETCHING_STATE:
      return {
        ...state,
        fetchingClaims: true
      };
    case types.CLAIMS_FETCH_ERROR:
      return {
        ...state,
        error: action.error,
        fetchingClaims: false
      };
    case types.SET_CLAIMS_DATA_REFRESH:
      return {
        ...state,
        needsRefresh: true
      };
    default:
      return state;
  }
}
