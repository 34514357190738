import {
  axiosInstanceOldAPI,
  axiosInstanceWithAuth
} from "./axios";
import {
  getCredentialsIfValid
} from "./auth/auth";


export const getAccountInfo = () => {
  let url = "/Accounts/GetAccountInfo";
 
  let credentials = getCredentialsIfValid();

  let data = {
    EmailAddress : credentials.username
  }
  
  if (credentials.username.indexOf('@') == -1) {
    return axiosInstanceOldAPI
    .post(url, {})
    .then(response => {
      return response.data.Item;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
  } else {
    return axiosInstanceWithAuth
      .post(url, data)
      .then(response => {
        return response.data.Item;
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  }
};


export const getAccountInfoLegacy = () => {
  let url = "/Accounts/GetAccountInfo";
  return axiosInstanceOldAPI
    .post(url, {})
    .then(response => {
      return response.data.Item;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
};


export const getTopAlertMessage = () => {
  let url = "/Messages/GlobalMessages?client_id=customer_center";
  return axiosInstanceOldAPI
    .get(url, {})
    .then(response => {
      //it has only one alert message always
        
      //return response.data.alert_messages[0].message;
        let alertMessages = response.data.alert_messages
        return alertMessages;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
};


export const validateZipcode = (zipcode) => {
  let url = "/checkzipcode?zipCode=" + zipcode;
  return axiosInstanceOldAPI
    .get(url, {})
    .then(response => {
      return response.data.result;
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

export const getNewBillingTokenOld = (amount, billingInfo) => {
  let url = "/Accounts/GetAcceptPaymentPageToken";

  let data = {
    Amount: amount,
    BillingInfo: billingInfo
  };

  return axiosInstanceOldAPI.post(url, data)
    .then(response => {
      return response.data;
    });
};

export const getNewBillingToken = (amount, billingInfo) => {
    let url = "/Accounts/GetAcceptPaymentPageToken";

    let data = {
        Amount: amount,
        BillingInfo: billingInfo
    };

    return axiosInstanceWithAuth
        .post(url, data)
        .then(response => {
            return response.data;
        });
};

export const getUpdateBillingToken = customerProfileId => {
  let url = "/Accounts/GetAcceptCustomerPageToken";

  let credentials = getCredentialsIfValid();

  let data = {
    customerProfileId: customerProfileId,
    EmailAddress : credentials.username
  };

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response.data;
    });
};

export const createAuthorizeNetCustomerProfile = (accountId, email) => {
  let url = "/Accounts/CreateAuthorizeNetCustomerProfile";

  let data = {
    account_id : accountId,
    email : email,
    EmailAddress : email
  }

  return axiosInstanceWithAuth
    .post(url, data)
    .then(response => {
      return response.data;
    });
}


export const getCaptureContext = () => {
  let url = "/enrollments/GetCaptureContext";

  return axiosInstanceWithAuth.get(url, {})
    .then(response => {
      return response.data;
    })
}


export const createNewBilling = (accountId, amount, transactionId) => {
  let url = "/enrollments/transferPet";

  let data = {
    AccountId : accountId,
    PaymentAmount : amount,
    TransactionId : transactionId
  }

  return axiosInstanceOldAPI.post(url, data)
    .then(response => {
      return response.data;
    });
}

export const updateBilling = (accountId, email, billingInfo) => {
  let url = "/Accounts/UpdateBilling";

  let data = {
    account_id : accountId,
    email : email,
    EmailAddress : email,
    BillingInfo: billingInfo,
    //UpdatemailingAlso: true will make address change in mailing address as well
    updateMailingAlso: false
  };

  return axiosInstanceWithAuth.post(url, data);
};

export const pullBillingInfoFromAuthorize = (accountId, email) => {
  let url = "/Accounts/PullBillingInfoFromAuthorize";

  let data = {
    account_id : accountId,
    email : email,
    EmailAddress : email
  }

  return axiosInstanceWithAuth.post(url, data);
}

export const updateProfile = (pet1ParentInfo, pet2ParentInfo) => {
  let url = "/Accounts/Profile";

  let data = {
    pet_parent_one: pet1ParentInfo,
    pet_parent_two: pet2ParentInfo
  };

  return axiosInstanceWithAuth
    .put(url, data)
    .then(response => {
      return {
        data: response.data
      };
    })
    .catch(function (error) {
      return Promise.reject({
        data: error
      });
    });
};

export const retryBilling = () => {
  let url = "/Accounts/RetryBilling";
  return axiosInstanceOldAPI
    .post(url)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return Promise.reject(err);
    });
};

export const setReimbursement = (reimbursementInfo, check) => {
  let url = "";

  if (check) url = "/Accounts/SetReimbursementToCheck";
  else url = "/Accounts/SetReimbursementToACH";

  return axiosInstanceOldAPI.post(url, reimbursementInfo);
};

export const cancelPolicy = (cancelInfo, forExpiryDate) => {
  let url = "/Accounts/CancelPolicy";

  if (forExpiryDate) {
    return axiosInstanceWithAuth
      .post(url, cancelInfo)
      .then(response => {
        if (response.data) return response.data;
        else
          return Promise.reject({
            errorCode: null,
            errorDescription: null,
            errorMessage: null
          });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  } else {
    return axiosInstanceWithAuth
      .put(url, cancelInfo)
      .then(response => {
        if (response.data) return response.data;
        else
          return Promise.reject({
            errorCode: null,
            errorDescription: null,
            errorMessage: null
          });
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  }
};

export const updatePhysicalAddress = (addressInfo, forMonthlyPremium) => {
  let url = "/Accounts/PhysicalAddress";

  if (forMonthlyPremium) {
    return axiosInstanceWithAuth
      .post(url, addressInfo)
      .then(response => {
        if (response.data) return response.data;
        else
          return Promise.reject({
            errorCode: null,
            errorDescription: null,
            errorMessage: null
          });
      })
      .catch(function (error) {
        return Promise.reject({
          data: error
        });
      });
  } else {
    return axiosInstanceWithAuth
      .put(url, addressInfo)
      .then(response => {
        if (response.data) return response.data;
        else
          return Promise.reject({
            errorCode: null,
            errorDescription: null,
            errorMessage: null
          });
      })
      .catch(function (error) {
        return Promise.reject({
          data: error
        });
      });
  }
}

